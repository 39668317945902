  import React, { useEffect, useState } from 'react';
  import {
    Box,
    Typography,
    Button,
    MenuItem,
    TextField,
    FormControl,
    Card,
    Grid,
    Modal,
    IconButton,
  } from '@mui/material';
  import CheckCircleIcon from '@mui/icons-material/CheckCircle';
  import { motion } from 'framer-motion';
  import mockInterviewImage1 from '../assets/mock-interview1.png';
  import mockInterviewImage2 from '../assets/mock-interview2.png';
  import Calendar from 'react-calendar';
  import 'react-calendar/dist/Calendar.css';
  import CloseIcon from '@mui/icons-material/Close';
  import ErrorIcon from '@mui/icons-material/Error';
  import 'react-toastify/dist/ReactToastify.css';
  import { useTheme } from '@emotion/react';
  import proxy from '../api/axios';
  import { DEV_INTERVIEW_PRICE, DSA_INTERVIEW_PRICE, interviewTimeSlots, PAYMENT_LIVE_KEY } from '../common/constants';
  import { Link, useNavigate } from 'react-router-dom';
  import { useSelector } from 'react-redux';
  import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
  import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
  import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
  import dayjs from 'dayjs';
  const BookingPage = () => {
    const theme = useTheme();
    const loggedInUser = useSelector((state)=>state.user.userInfo);
    const [interviewType, setInterviewType] = useState('Intermediate');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(dayjs());
    const [timeSlot, setTimeSlot] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedSection, setSelectedSection] = useState('');
    const [amount, setAmount] = useState(1); // Assume amount is $100 for now
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [failedPaymentModalOpen, setFailedPaymentModalOpen] = useState(false);
    const navigate = useNavigate();

    const handleBooking = async () => {
      setLoading(true);
      try {
        const booking = {
          name,
          email,
          interviewType,
          date,
          timeSlot
        }
        // Create an order on the backend
        const orderResponse = await proxy.post('/payments/create-order',
          { amount, currency: 'INR', booking });
        const { orderId } = orderResponse.data;
    
        // Razorpay options
        const options = {
          key: PAYMENT_LIVE_KEY,
          amount: amount * 100,
          currency: 'INR',
          name: 'CrafterHack',
          description: 'Schedule Mock Interview',
          order_id: orderId,
          handler: async (response) => {
            // Handle payment success
            const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;
            
            const verifyResponse = await proxy.post('/payments/verify-payment', {
              razorpay_order_id,
              razorpay_payment_id,
              razorpay_signature,
              amount
            });
    
            if (verifyResponse.data.status === 'success') {
              // Close the booking modal and open the confirmation modal
              setModalOpen(false);
              setConfirmationModalOpen(true);
            } else {
              setFailedPaymentModalOpen(true);
            }
          },
          prefill: {
            name: name,
            email: email,
            contact: contactNumber,
          },
          theme: {
            color: '#3399cc',
          },
        };
    
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } catch (error) {
        console.error('Payment Error:', error);
      } finally {
        setLoading(false);
      }
    };

    const handleRetryPayment = () =>{
      setFailedPaymentModalOpen(false);
    }
    
    const handleDateChange = (newDate) => {
      setDate(newDate);
    };
    
    return (
      <Box sx={{ padding: '20px', bgcolor: theme.palette.mode === 'dark' ? '#333' : '#fff', borderRadius: '10px' }}>
        <Typography variant="h5" sx={{ mb: 3, textAlign: 'center', fontWeight: 'bold' }}>
          Schedule Your Mock Interview
        </Typography>
        <Typography variant="body1" sx={{ mb: 3, textAlign: 'center', color: '#555' }}>
          Enhance your skills with personalized mock interviews tailored to your needs.
        </Typography>

        {/* Mock Interview Sections */}
        <Grid container spacing={4} sx={{ mb: 4 }}>
          {/* DSA Interviews Section */ }
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', borderRadius: '10px', boxShadow: '0 2px 10px rgba(0,0,0,0.1)', textAlign: 'center' }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                Mock Interviews for DSA
              </Typography>
              <motion.img
                src={mockInterviewImage1}
                alt="Mock Interview DSA"
                style={{ width: '100%', borderRadius: '10px', marginBottom: '20px' }}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              />
              <Typography variant="body2" sx={{ mb: 2 }}>
                Prepare for Data Structures and Algorithms interviews with sessions tailored for:
              </Typography>
              <ul style={{ textAlign: 'left' }}>
                <li>👶 <strong>Beginner</strong>: Understand the fundamentals and basic problem-solving techniques.</li> <br/>
                <li>🌟 <strong>Intermediate</strong>: Dive deeper into complex algorithms and data structures.</li> <br/>
                <li>🏆 <strong>Advanced</strong>: Master advanced topics and strategies for competitive interviews.</li>
              </ul>
              <Button
                variant="contained"
                onClick={() => { setModalOpen(true); setSelectedSection('DSA'); setAmount(loggedInUser?.isPremiumUser ? 1 : DSA_INTERVIEW_PRICE) }}
                sx={{ mt: 2, bgcolor: 'primary.main', '&:hover': { bgcolor: 'primary.dark' } }}
              >
                Schedule Now
              </Button>
            </Card>
          </Grid>

          {/* Dev Interviews Section */}
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', borderRadius: '10px', boxShadow: '0 2px 10px rgba(0,0,0,0.1)', textAlign: 'center' }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                Mock Interviews for Developers
              </Typography>
              <motion.img
                src={mockInterviewImage2}
                alt="Mock Interview Dev"
                style={{ width: '100%', borderRadius: '10px', marginBottom: '20px' }}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              />
              <Typography variant="body2" sx={{ mb: 2 }}>
                Get ready for developer roles with interviews focusing on:
              </Typography>
              <ul style={{ textAlign: 'left' }}>
                  <li>🖥️ <strong>Frontend Development</strong>: Skills in React, Angular, Vue.js, and responsive design principles.</li> <br/>
                  <li>⚙️ <strong>Backend Development</strong>: Proficiency in Node.js, Express, C#, and API design, including RESTful services and microservices architecture.</li> <br/>
                  <li>🔧 <strong>Fullstack Development</strong>: Integrating frontend and backend knowledge, covering server-side logic and client-side rendering.</li> <br/>
                  <li>💡 <strong>Object-Oriented Programming (OOP)</strong>: Understanding core OOP principles such as encapsulation, inheritance, and polymorphism in languages like C# and Java.</li>  <br/>
                  <li>💾 <strong>Databases & SQL</strong>: Experience with relational databases (like MySQL, PostgreSQL) and NoSQL databases (like MongoDB), including writing complex SQL queries and database design.</li>
              </ul>
              <Button
                variant="contained"
                onClick={() => { setModalOpen(true); setSelectedSection('Dev'); setAmount(DEV_INTERVIEW_PRICE) }}
                sx={{ mt: 2, bgcolor: 'primary.main', '&:hover': { bgcolor: 'primary.dark' } }}
              >
                Schedule Now
              </Button>
            </Card>
          </Grid>
        </Grid>

        {/* How It Works Section */}
      <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      >
      <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12}>
          <Card sx={{ padding: '30px', borderRadius: '10px', boxShadow: '0 2px 10px rgba(0,0,0,0.1)', textAlign: 'center' }}>
              <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '1.8rem', lineHeight: '1.5' }}>
              How It Works
              </Typography>
              <Typography variant="body2" sx={{ mb: 2, fontSize: '1rem', lineHeight: '1.6', color: '#555' }}>
              Follow these simple steps to schedule your mock interview:
              </Typography>
              <ul style={{ textAlign: 'left', margin: '0 auto', maxWidth: '900px', listStyle: 'none', padding: 0 }}>
              <li style={{ marginBottom: '1.2rem', fontSize: '1rem', lineHeight: '1.2' }}>1️⃣ <strong>Choose Your Interview Type:</strong> Select between DSA or Developer interviews, and specify your level.</li>
              <li style={{ marginBottom: '1.2rem', fontSize: '1rem', lineHeight: '1.2' }}>2️⃣ <strong>Select Date and Time:</strong> Pick a convenient date and time from the available slots.</li>
              <li style={{ marginBottom: '1.2rem', fontSize: '1rem', lineHeight: '1.2' }}>3️⃣ <strong>Fill Out Your Details:</strong> Provide your name and email address to confirm your booking.</li>
              <li style={{ marginBottom: '1.2rem', fontSize: '1rem', lineHeight: '1.2' }}>4️⃣ <strong>Make a Payment:</strong> Complete the payment to secure your interview session.</li>
              <li style={{ marginBottom: '1.2rem', fontSize: '1rem', lineHeight: '1.2' }}>5️⃣ <strong>Prepare for Your Interview:</strong> Get ready for a personalized mock interview experience!</li>
              </ul>
          </Card>
          </Grid>
      </Grid>
      </motion.div>

        {/* Booking Form Modal */}
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Box
            sx={{
              width: { xs: '90%', sm: '750px' }, // Responsive width
              maxWidth: '100vw', // Prevents horizontal overflow
              padding: '20px',
              bgcolor: theme.palette.mode === 'dark' ? '#333' : '#fff',
              borderRadius: '10px',
              boxShadow: 24,
              margin: 'auto',
              marginTop: '5%',
              position: 'relative',
              maxHeight: '90vh', // Limits height to 90% of viewport height
              overflowY: 'auto', // Enables vertical scrolling when content overflows
            }}
          >
            <IconButton
              onClick={() => setModalOpen(false)}
              sx={{ position: 'absolute', top: '10px', right: '10px' }}
            >
              <CloseIcon />
            </IconButton>

            <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
              Schedule {selectedSection === 'DSA' ? 'DSA' : 'Dev'} Interview
            </Typography>

            <Grid container spacing={2}>
              {/* Interview Type */}
              <Grid item xs={12} sm={6}>
                {
                  selectedSection === 'DSA' && (
                  <FormControl fullWidth>
                    <TextField
                      select
                      label="Interview Type"
                      value={interviewType}
                      onChange={(e) => setInterviewType(e.target.value)}
                    >
                      <MenuItem value="Beginner">Beginner</MenuItem>
                      <MenuItem value="Intermediate">Intermediate</MenuItem>
                      <MenuItem value="Advanced">Advanced</MenuItem>
                    </TextField>
                  </FormControl>
                  )
                }
                
                {
                  selectedSection === 'Dev' && (
                  <FormControl fullWidth>
                    <TextField
                      select
                      label="Interview Type"
                      value={interviewType}
                      onChange={(e) => setInterviewType(e.target.value)}
                    >
                      <MenuItem value="Frontend">Frontend Development</MenuItem>
                      <MenuItem value="Backend">Backend Development</MenuItem>
                      <MenuItem value="Fullstack">Fullstack Development</MenuItem>
                    </TextField>
                  </FormControl>
                  )
                }
                
              </Grid>

              {/* Name */}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Grid>

              {/* Email */}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Grid>

              {/* Contact Number */}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Contact Number"
                  variant="outlined"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
              <Box sx={{ mb: 2, maxHeight: '300px' }}> {/* Adjusting height */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar 
                    onChange={handleDateChange} 
                    value={date}
                    views={['day']}
                    sx={{ '.MuiPickersCalendarHeader-root': { minHeight: '50px' }}}
                    shouldDisableDate={(date) => {
                      if(!loggedInUser?.isPremiumUser){
                        return false;
                      }
                      const day = date.day(); // 0: Sunday, 6: Saturday
                      return day !== 0 && day !== 6; // Disable all days that are not Saturday (6) or Sunday (0)
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
              

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    select
                    label="Time Slot"
                    value={timeSlot}
                    onChange={(e) => setTimeSlot(e.target.value)}
                  >
                    {interviewTimeSlots.map((slot) => (
                      <MenuItem key={slot} value={slot}>
                        {slot}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl> <br/><br/>
                <Button
                  variant="contained"
                  onClick={handleBooking}
                  disabled={!interviewType || !name || !email || !timeSlot}
                  sx={{ width: '100%', bgcolor: 'primary.main', '&:hover': { bgcolor: 'primary.dark' } }}
                >
                  Pay {amount} INR to Confirm
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal open={confirmationModalOpen} onClose={() => setConfirmationModalOpen(false)}>
          <Box
            sx={{
              width: '450px',
              padding: '30px',
              bgcolor: 'background.paper',
              borderRadius: '15px',
              boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
              margin: 'auto',
              marginTop: '10%',
              position: 'relative',
              textAlign: 'center',
            }}
          >
            {/* Close Icon */}
            <IconButton
              onClick={() => setConfirmationModalOpen(false)}
              sx={{
                position: 'absolute',
                top: '15px',
                right: '15px',
                bgcolor: 'grey.100',
                '&:hover': {
                  bgcolor: 'grey.300',
                },
                p: 1,
                borderRadius: '50%',
              }}
            >
              <CloseIcon />
            </IconButton>

            {/* Success Icon */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
              <Box
                sx={{
                  bgcolor: 'success.light',
                  color: 'success.main',
                  p: 2,
                  borderRadius: '50%',
                  display: 'inline-flex',
                }}
              >
                <CheckCircleIcon fontSize="large" />
              </Box>
            </Box>

            {/* Title */}
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
              Payment Successful!
            </Typography>

            {/* Subtitle */}
            <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
              You will receive a confirmation email shortly, and your interview will be scheduled soon.
            </Typography>

            {/* Related Content */}
            <Box
              sx={{
                bgcolor: 'grey.100',
                borderRadius: '10px',
                p: 2,
                mb: 4,
                textAlign: 'left',
              }}
            >
              <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
                Looking for something existing ?
              </Typography>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="body2" component={Link} to="/contests" sx={{ color: 'primary.main', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                  Participate in coding contests
                </Typography>
              </Box>
            </Box>

            {/* Solve Problems Button */}
            <Button
              variant="contained"
              onClick={() => navigate('/problems')}
              sx={{
                width: '100%',
                bgcolor: 'primary.main',
                py: 1.5,
                mb: 2,
                borderRadius: '8px',
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
              }}
            >
              Solve Coding Problems
            </Button>

            {/* Close Button */}
            <Button
              variant="outlined"
              onClick={() => setConfirmationModalOpen(false)}
              sx={{
                width: '100%',
                borderColor: 'primary.main',
                color: 'primary.main',
                py: 1.5,
                borderRadius: '8px',
                '&:hover': {
                  borderColor: 'primary.dark',
                  color: 'primary.dark',
                },
              }}
            >
              Close
            </Button>
          </Box>
        </Modal>
        <Modal open={failedPaymentModalOpen} onClose={() => setFailedPaymentModalOpen(false)}>
          <Box
            sx={{
              width: '450px',
              padding: '30px',
              bgcolor: 'background.paper',
              borderRadius: '15px',
              boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
              margin: 'auto',
              marginTop: '10%',
              position: 'relative',
              textAlign: 'center',
            }}
          >
            {/* Close Icon */}
            <IconButton
              onClick={() => setFailedPaymentModalOpen(false)}
              sx={{
                position: 'absolute',
                top: '15px',
                right: '15px',
                bgcolor: 'grey.100',
                '&:hover': {
                  bgcolor: 'grey.300',
                },
                p: 1,
                borderRadius: '50%',
              }}
            >
              <CloseIcon />
            </IconButton>

            {/* Error Icon */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
              <Box
                sx={{
                  bgcolor: 'error.light',
                  color: 'error.main',
                  p: 2,
                  borderRadius: '50%',
                  display: 'inline-flex',
                }}
              >
                <ErrorIcon fontSize="large" />
              </Box>
            </Box>

            {/* Title */}
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
              Payment Failed
            </Typography>

            {/* Subtitle */}
            <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
              Unfortunately, your payment could not be processed at this time. Please try again or contact support for assistance.
            </Typography>

            {/* Related Content */}
            <Box
              sx={{
                bgcolor: 'grey.100',
                borderRadius: '10px',
                p: 2,
                mb: 4,
                textAlign: 'left',
              }}
            >
              <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
                Possible Reasons:
              </Typography>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                  - Insufficient funds in your account.
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                  - Invalid card details or expired card.
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                  - Payment gateway issues.
                </Typography>
              </Box>
            </Box>

            {/* Retry Payment Button */}
            <Button
              variant="contained"
              onClick={() => handleRetryPayment()} // Implement the function to retry payment
              sx={{
                width: '100%',
                bgcolor: 'error.main',
                py: 1.5,
                mb: 2,
                borderRadius: '8px',
                '&:hover': {
                  bgcolor: 'error.dark',
                },
              }}
            >
              Retry Payment
            </Button>

            {/* Close Button */}
            <Button
              variant="outlined"
              onClick={() => setFailedPaymentModalOpen(false)}
              sx={{
                width: '100%',
                borderColor: 'primary.main',
                color: 'primary.main',
                py: 1.5,
                borderRadius: '8px',
                '&:hover': {
                  borderColor: 'primary.dark',
                  color: 'primary.dark',
                },
              }}
            >
              Close
            </Button>
          </Box>
        </Modal>
      </Box>
    );
  };

  export default BookingPage;
