import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Avatar, List, ListItem, ListItemAvatar, ListItemText, Pagination, CircularProgress, InputBase, IconButton, Divider } from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'; // Trophy icon
import StarIcon from '@mui/icons-material/Star'; // Icon for ranking
import SearchIcon from '@mui/icons-material/Search'; // Search icon
import { styled } from '@mui/material/styles';
import proxy from '../api/axios';
import { useNavigate } from 'react-router-dom';
import { showAuth } from '../redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';

// Styled components to make the leaderboard more appealing
export const TrophyIcon = styled(EmojiEventsIcon)(({ theme, color }) => ({
  color: color || theme.palette.secondary.main,
  fontSize: '30px',
}));
const SearchContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  marginBottom: theme.spacing(2),
  transition: 'background-color 0.3s', // Smooth transition for background color
  '&:hover': {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#333', // Change background color on hover
    boxShadow: theme.shadows[4],
  },
  width: '100%',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  padding: theme.spacing(1, 1, 1, 0),
  paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    transition: 'background-color 0.3s', // Smooth transition for input background
    backgroundColor: theme.palette.background.default, // Input background color
  },
}));


const LeaderboardPage = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1); // Track the current page
  const [users, setUsers] = useState([]); // State to hold fetched user data
  const [loading, setLoading] = useState(true); // State for loading
  const usersPerPage = 10; // Maximum users per page
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState(''); // State for search term

  // Fetch leaderboard data from the API
  const fetchLeaderboard = async () => {
    try {
      const response = await proxy.get('/users/leaderboard'); // Adjust the API endpoint as needed
      setUsers(response.data.leaderboard); // Assuming the response contains an array of users
    } catch (error) {
      console.error('Error fetching leaderboard data: ', error);
    } finally {
      setLoading(false); // Set loading to false when done
    }
  };

  useEffect(() => {
    fetchLeaderboard(); // Fetch data when the component mounts
  }, []);

  // Calculate the users to be shown on the current page
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleUserClick = (username) => {
    if (!isLoggedIn) {
      dispatch(showAuth());
      return;
    }
    navigate(`/user/${username}`);
  };

  // Filter users based on search term
  const filteredUsers = users.filter(user =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const startIndex = (page - 1) * usersPerPage;
  const selectedUsers = filteredUsers.slice(startIndex, startIndex + usersPerPage);

  return (
    <Box sx={{ padding: '20px', display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
      <Box sx={{ width: { xs: '100%', sm: '80%' }, mx: 'auto' }}> {/* Responsive width */}
        <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
          Leaderboard
        </Typography>

        {/* Search Bar */}
        <SearchContainer>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search Username…"
            inputProps={{ 'aria-label': 'search' }}
            value={searchTerm}
            onChange={(e) => { setSearchTerm(e.target.value); setPage(1); }}
          />
        </SearchContainer>

        {/* Show loading indicator if loading */}
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Paper sx={{ width: '100%', padding: '20px', bgcolor: 'background.default' }}>
            <List>
              {selectedUsers.map((user, index) => (
                <ListItem
                  key={index}
                  sx={{
                    mb: 2,
                    borderRadius: '8px',
                    bgcolor: 'background.paper',
                    transition: 'transform 0.3s',
                    '&:hover': {
                      transform: 'scale(1.005)',
                    },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={() => handleUserClick(user.username)}>
                    {/* Avatar and Trophy for top 3 */}
                    <ListItemAvatar>
                      {!searchTerm && startIndex + index < 3 ? (
                        <TrophyIcon
                          color={startIndex + index === 0 ? 'gold' : startIndex + index === 1 ? 'silver' : 'bronze'}
                        />
                      ) : (
                        users.indexOf(user)<  3 ? <TrophyIcon
                        color={users.indexOf(user) === 0 ? 'gold' : users.indexOf(user) === 1 ? 'silver' : 'bronze'}
                      /> : <Avatar sx={{ bgcolor: 'secondary.main' }}>{users.indexOf(user)+1}</Avatar>
                      )}
                    </ListItemAvatar>

                    <ListItemText
                      primary={
                        <Typography variant="body" sx={{ cursor: 'pointer', fontWeight: users.indexOf(user) < 3 ? 'bold' : 'normal' }}>
                          {user.name}
                        </Typography>
                      }
                      secondary={
                        <Typography sx={{ color: 'text.secondary' }}>
                          Points: {user.points}
                        </Typography>
                      }
                    />
                  </Box>

                  {/* Star Icon for ranking */}
                  {users.indexOf(user) <= 4 && <StarIcon sx={{ color: 'warning.main' }} />}
                  {users.indexOf(user) > 4 && <StarIcon sx={{ color: 'warning' }} />}
                </ListItem>
              ))}
            </List>

            {/* Pagination Controls */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Pagination
                count={Math.ceil(filteredUsers.length / usersPerPage)} // Adjust count based on filtered users
                page={page}
                onChange={handleChangePage}
                color="primary"
              />
            </Box>
          </Paper>
        )}
      </Box>
    </Box>
  );
};

export default LeaderboardPage;
