import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  Stack,
  CircularProgress,
  List,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import TimerIcon from '@mui/icons-material/Timer';
import proxy from '../api/axios';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import ProblemPage from './ProblemPage';

const LiveContest = () => {
  const { contestId } = useParams();
  const navigate = useNavigate();
  const [contest, setContest] = useState(null);
  const [problems, setProblems] = useState([]);
  const [selectedProblem, setSelectedProblem] = useState('All');
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [fullscreenDialog, setFullscreenDialog] = useState(true); // Initially true to show fullscreen prompt
  const [isFullscreen, setIsFullscreen] = useState(false); // Track fullscreen status
  const [successDialog, setSuccessDialog] = useState(false); // Dialog for successful submission
  const containerRef = useRef(null);
  const [fullScreenRequired, setFullScreenRequired] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [timerStarted, setTimerStarted] = useState(false); // Track if timer has started

  useEffect(() => {
    const fetchContestDetails = async () => {
        try {
            setIsLoading(true);
            const { data } = await proxy.get(`/contests/${contestId}`);
            setContest(data);
            setProblems(data.problems || []);
            if(data.duration<=0){
              toast.info('Your time is over, check results');
              navigate(`/contests/${data._id}/result`);
            }
            setTimeRemaining(data.duration * 60); // Set timer based on contest duration (in seconds)
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching contest details:', error);
            toast.error(error.response.data.message);
            navigate('/contests')
        }
    };

    fetchContestDetails();
  }, [contestId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining((prev) => {
        if (prev <= 0) {
          clearInterval(interval);
          setFullscreenDialog(false);
          setFullScreenRequired(false);
          exitFullScreen();
          setIsDialogOpen(true); // Open dialog when time is up
          return 0;
        }
        return timerStarted ? prev - 1 : prev; // Decrease time
      });
    }, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [timerStarted]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  const handleSelectProblem = (problem) => {
    setSelectedProblem(problem);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    navigate('/contests'); // Redirect to home page after contest ends
  };

  const enterFullScreen = () => {
    if (containerRef.current.requestFullscreen) {
      containerRef.current.requestFullscreen();
    } else if (containerRef.current.mozRequestFullScreen) { // Firefox
      containerRef.current.mozRequestFullScreen();
    } else if (containerRef.current.webkitRequestFullscreen) { // Chrome, Safari, and Opera
      containerRef.current.webkitRequestFullscreen();
    } else if (containerRef.current.msRequestFullscreen) { // IE/Edge
      containerRef.current.msRequestFullscreen();
    }
    setIsFullscreen(true);
    setFullscreenDialog(false); // Close the fullscreen prompt once entered

    if (!timerStarted) {
      setTimerStarted(true); // Mark that the timer has started
    }
  };

  const handleFullscreenChange = () => {
    if (!document.fullscreenElement && fullScreenRequired) {
      setFullscreenDialog(true); // Show dialog if fullscreen is exited
      setIsFullscreen(false);
    }
  };

  const handleSubmitContest = async () => {
    try {
        setIsLoading(true);
        const { data } = await proxy.put(`/contests/${contestId}/submit`);
        setIsLoading(false);
        setFullScreenRequired(false);
        // Handle contest submission logic here
        setSuccessDialog(true); // Show success dialog
        setFullscreenDialog(false); // Ensure fullscreen dialog does not appear after submission
        exitFullScreen(); // Exit fullscreen mode
    } catch (error) {
        console.error('Error fetching contest details:', error);
        toast.error('Could not load contest details.');
    } finally{
        setIsLoading(false);
    }
    

  };

  const exitFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  };

  const handleSuccessDialogClose = () => {
    setSuccessDialog(false);
    navigate('/'); // Redirect to home page
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);

  return (
    <Box ref={containerRef} sx={{ display: 'flex', padding: '20px', backgroundColor: isFullscreen ? '#f0f4f8' : 'inherit' }}>
      {contest && isFullscreen && (
        <>
          {/* Col-A: Fixed Button Column */}
          <Box sx={{ minWidth: '100px', marginRight: '20px' }}>
            <Stack spacing={2} sx={{ alignItems: 'center' }}>
              {/* 'All' Button */}
              <Button
                variant={selectedProblem === 'All' ? 'contained' : 'outlined'}
                onClick={() => handleSelectProblem('All')}
                sx={{
                  borderRadius: '50%',
                  width: '40px',
                  height: '40px',
                  minWidth: '40px',
                }}
              >
                All
              </Button>

              {/* Number Buttons */}
              {problems.map((_, index) => (
                <Button
                  key={index}
                  variant={selectedProblem === index ? 'contained' : 'outlined'}
                  onClick={() => handleSelectProblem(index)}
                  sx={{
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    minWidth: '40px',
                  }}
                >
                  {index + 1}
                </Button>
              ))}
            </Stack>
          </Box>

          {/* Col-B: Problem List or Specific Problem */}
          <Box sx={{ flexGrow: 1, position: 'relative' }}>
            {/* Contest Title */}
            <Typography variant="h4" sx={{ marginBottom: '20px' }}>
              {contest.title}
            </Typography>

            {/* Timer in the top-right corner */}
            <Box sx={{ position: 'absolute', top: '10px', right: '10px' }}>
              <Stack direction="row" alignItems="center">
                <TimerIcon color="primary" />
                <Typography variant="h6" sx={{ ml: 1 }}>
                  {formatTime(timeRemaining) + ' Remaining'}
                </Typography>
              </Stack>
            </Box>

            {selectedProblem === 'All' ? (
              <Paper sx={{ padding: '20px' }}>
                <List>
                  {problems.map((problem, index) => (
                    <ListItem
                      key={problem._id}
                      sx={{
                        padding: '10px',
                        borderBottom: '1px solid #ccc',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="h6">{`${index + 1}. ${problem.title}`}</Typography>
                      <Button
                        variant="contained"
                        onClick={() => handleSelectProblem(index)}
                      >
                        Solve
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            ) : (
              <Paper sx={{ padding: '20px' }}>
                <ProblemPage contestId={contestId} problem={problems[selectedProblem]} />
              </Paper>
            )}

            {/* Submit and Exit Button */}
            <Box sx={{ position: 'absolute', bottom: '1px', right: '20px' }}>
              <Button variant="contained" onClick={handleSubmitContest}>
                Final Submit
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
            { isLoading && <CircularProgress />}
          </Box>
        </>
      )}

      {/* Dialog for time up */}
      <Dialog open={isDialogOpen && contest && timerStarted} onClose={handleDialogClose}>
        <DialogTitle>Time's Up!</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Your contest time has expired. Thank you for participating!</Typography>
          <Typography variant="body2" sx={{ mt: 2 }}>
            Please check your results and prepare for the next contest!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Got it
          </Button>
        </DialogActions>
      </Dialog>

      {/* Fullscreen entry dialog */}
      <Dialog open={fullscreenDialog && fullScreenRequired}>
        <DialogTitle>Enter Fullscreen</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            This contest requires fullscreen mode for the best experience. Please click the button below to enter fullscreen mode before starting the contest.
          </Typography>
        </DialogContent>
        <DialogActions>
          {
            timerStarted &&
          (<Button
            onClick={handleSubmitContest}
            color="primary"
            variant="contained"
          >
            Submit & Exit
          </Button>)
          }
          <Button
            onClick={enterFullScreen}
            color="success"
            variant="contained"
          >
            Enter Fullscreen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success dialog */}
      <Dialog open={successDialog} onClose={handleSuccessDialogClose}>
        <DialogTitle>Contest Submitted Successfully!</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Your contest submission has been recorded. Thank you for participating!</Typography>
          <Typography variant="body2" sx={{ mt: 2 }}>
            Keep practicing and improving your skills!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LiveContest;
