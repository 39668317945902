import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TableContainer,
  Avatar,
  IconButton,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import proxy from '../api/axios';
import { toast } from 'react-toastify';
import { styled } from '@mui/system';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ScoreIcon from '@mui/icons-material/Score';
import { useTheme } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import { TrophyIcon } from './LeaderboardPage';

// Animation for the page
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// Styled components
const StyledBox = styled(Box)(({ theme }) => ({
  maxWidth: '80%',
  margin: '0 auto',
  padding: theme.spacing(3),
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: '15px',
  boxShadow: '0 6px 30px rgba(0, 0, 0, 0.1)',
  animation: `${fadeIn} 0.5s ease-in-out`,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
  '&:last-child': {
    backgroundColor: theme.palette.secondary.light,
  },
}));

const HoverableTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    transition: 'background-color 0.3s ease',
    cursor: 'pointer',
  },
}));

const ContestResult = () => {
  const { contestId } = useParams();
  const [contest, setContest] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    const fetchContestResults = async () => {
      try {
        setIsLoading(true);
        const { data } = await proxy.get(`/contests/${contestId}/result`);
        setContest(data.contest);
        setParticipants(data.participants);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching contest results:', error);
        toast.error('Could not load contest results.');
        setIsLoading(false);
      }
    };

    fetchContestResults();
  }, [contestId]);

  const formatDateTime = (datetime) => {
    return new Date(datetime).toLocaleString();
  };

  if (isLoading) {
    return (
      <StyledBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </StyledBox>
    );
  }

  return (
    <StyledBox sx={{ mb: 4 }}>
      {contest && (
        <StyledBox sx={{ mb: 4 }}>
          <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 2 }}>
              {contest.title}
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center', color: 'text.secondary', mb: 1 }}>
              {contest.description}
            </Typography>
            <Grid container spacing={2} justifyContent="center" sx={{ mb: 2 }}>
              <Grid item xs={12} sm={12} display="flex" alignItems="center">
                <AccessTimeIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                <Typography variant="body1"><strong>Duration:</strong> {contest.duration} minutes</Typography>
              </Grid>
              <Grid item xs={12} sm={6} display="flex" alignItems="center" justifyContent="flex-start">
                <DateRangeIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                <Typography variant="body1" sx={{ textAlign: 'left' }}>
                  <strong>Start Time:</strong> {formatDateTime(contest.startTime)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display="flex" alignItems="center" justifyContent="flex-start">
                <DateRangeIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                <Typography variant="body1" sx={{ textAlign: 'left' }}>
                  <strong>End Time:</strong> {formatDateTime(contest.endTime)}
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={3}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell><Typography variant="h6">Rank</Typography></StyledTableCell>
                    <StyledTableCell><Typography variant="h6">Participant</Typography></StyledTableCell>
                    <StyledTableCell><Typography variant="h6">Score ({contest.problems.length * 10} Points)</Typography></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {participants.length > 0 ? (
                    participants.map((participant, index) => (
                      <HoverableTableRow key={index}>
                        <TableCell>{index + 1}</TableCell> {/* Display rank */}
                        <TableCell>
                          <Grid container alignItems="center">
                            <Grid item>
                            {index < 3 ? (
                              <TrophyIcon
                                color={index === 0 ? 'gold' : index === 1 ? 'silver' : 'bronze'}
                                sx={{ mr: 2 }}
                              />
                              ) : (
                                index<  3 ? <TrophyIcon
                                color={index === 0 ? 'gold' : index === 1 ? 'silver' : 'bronze'}
                                sx={{ mr: 2 }}
                              /> : <Avatar   alt={participant.user.name} src={participant.user.profilePicture} sx={{ mr: 1 }} />
                              )}
                              
                            </Grid>
                            <Grid item>
                              <Typography>{participant.user.name}</Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid container alignItems="center">
                            <Grid item>
                              <ScoreIcon sx={{ mr: 1, color: theme.palette.secondary.main }} />
                            </Grid>
                            <Grid item>
                              {participant.score} Points
                            </Grid>
                          </Grid>
                        </TableCell>
                      </HoverableTableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography variant="body1" sx={{ textAlign: 'center', py: 2 }}>
                          No participants yet.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </StyledBox>
      )}
    </StyledBox>
  );
};

export default ContestResult;
